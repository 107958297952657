<script setup>

</script>

<template>
  <section>
    <div class="list-container">
      <h1>What Can I Pay For With Final Expense Insurance?</h1>
      <p>Final expense insurance was created to help older adults cover end-of-life costs.</p>
      <ul>
        <li>Funeral home rental fees</li>
        <li>Other funeral home costs, such as a viewing, service, or memorial package</li>
        <li>Transportation to and from the cemetery, if you choose burial</li>
        <li>Burial expenses if you choose a burial, such as plot, grave marker, and casket</li>
        <li>Embalming costs, if you plan to have an open casket</li>
        <li>Cremation costs if you decide to be cremated, such as a cremation casket or urn and transportation of your remains</li>
        <li>The officiant fee for service</li>
        <li>Additional touches for your final sendoff, like the ceremony, music, and flowers</li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  section {
    width: 100%;
    .list-container {
      font-family: Arial, sans-serif;
      max-width: 1200px;
      margin: auto;
      padding: 50px 20px;
      h1 {
        font-size: 30px;
        color: #005232;
        text-align: center;
      }
      p {
        color: #005232;
        font-size: 20px;
        text-align: center;
        padding-top: 5px;
      }
      ul {
        margin-top: 40px;
        list-style-type: none;
        position: relative;
        padding: 0;
        li {
          background-color: #63d1a2;
          color: white;
          font-size: 20px;
          padding: 15px;
          margin: 10px 0;
          text-shadow: 1px 1px 1px #007b56;
          border-radius: 5px;
          transition: all ease-in-out .3s;
          cursor: default;
          border: 1px solid #007b56;
          &:hover {
            background-color: #00AB6D;
            transform: scale(1.01);
          }
        }
      }
    }
  }
</style>