<script setup>

</script>

<template>
  <div class="tcpa-flow-container">
    <label for="">
      <input type="hidden" id="leadid_tcpa_disclosure"/>
      <p class="text">If you would like to get your results over the phone, please contact us at <a href="tel:7162212173">(716) 221-2173</a></p>
      <p class="compliance-text">
        By clicking "GET MY FREE FINAL EXPENSE QUOTE", I confirm I have read and agreed to this website's <a href="/terms-of-use" target="_blank">Terms of Service</a> and
        <a href="/privacy-policy" target="_blank">Privacy Policy</a> and provide my express written consent via electronic signature authorizing
        Final Expense Fund and <a href="/partners" target="_blank">one or more law firms, their agents and marketing partners</a> to contact me about
        my case and other related legal products and services to the number and email address I provided (including any wireless number). I further
        expressly consent to receive telemarketing emails, calls, text messages, pre-recorded messages, and artificial voice messages via an
        autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state, federal or corporate
        “Do Not Call” list. I understand that my consent is not a condition of purchase of any goods or services and that standard message and
        data rates may apply. For California residents see <a href="/privacy-policy#ca-privacy-rights" target="_blank">here</a>.
      </p>
    </label>
  </div>

</template>

<style lang="scss" scoped>

.tcpa-flow-container{
  display: flex;
  align-items: center;
  padding: 10px;
  .text {
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: #005233;
    padding-bottom: 30px;
    text-align: center;
    a {
      font-weight: bold;
      color: #63d1a2;
      text-decoration: none;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #007b56;
      font-size: 20px;
      display: inline-block;
      &:hover {
        color: #00AB6D;
      }
    }
  }
  .compliance-text {
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #717171;
    font-weight: 400;
    text-align: justify;
    a {
      text-decoration: none;
      color: #005233;
    }
  }
}

</style>