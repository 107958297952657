<script setup>
import TCPACompliance from '../components/TCPACompliance'
import { DatePicker } from 'v-calendar'
import { onMounted, reactive, ref, watch } from 'vue'
import format from 'date-fns/format'
import axios from 'axios'
import router from '../router'
import { getJornayaLeadIdToken } from '../js/utils'
import { stateList} from '../js/constants'

const currentDate = new Date();
let year = currentDate.getFullYear();
let month = currentDate.getMonth();
let day = currentDate.getDate();
let minDate = new Date(year - 120, month, day);
let maxDate = new Date(year - 14, month, day)

const state = reactive({
  firstNameValue: '',
  firstNameError: false,
  lastNameValue: '',
  lastNameError: false,
  emailValue: '',
  emailError: false,
  phoneValue: '',
  phoneError: false,
  birthDateValue: '',
  birthDateError: false,
  addressValue: '',
  addressError: false,
  cityValue: '',
  cityError: false,
  stateValue: '',
  stateError: false,
  zipValue: '',
  zipError: false,
  userAgent: navigator.userAgent,
  ipAddress: '',
  loading: false,
  buttonValue: 'GET MY FREE FINAL EXPENSE QUOTE',
  jornaya_id: '',
})

onMounted(async () => {
  try {
    const r = await fetch("https://api.ipify.org?format=json")
    const response = await r.json()
    state.ipAddress = response.ip
  } catch (e) {
    const r = await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    const response = await r.text()
    const ipArray = response.match(/ip=([^\n]+)/)
    if (ipArray && ipArray.length > 1) {
      state.ipAddress = ipArray[1]
    }
  }

  getJornayaLeadIdToken()
    .then(leadid_token => {
      state.jornaya_id = leadid_token
    })
})

watch(() => [ state.firstNameValue ], () => {
  if (state.firstNameValue.length > 0) state.firstNameError = false
})

watch(() => [ state.lastNameValue ], () => {
  if (state.lastNameValue.length > 0) state.lastNameError = false
})

watch(() => [ state.emailValue ], () => {
  if (state.emailValue.length > 0) state.emailError = false
})

watch(() => [ state.phoneValue ], () => {
  if (state.phoneValue.length > 0) state.phoneError = false
})

watch(() => [state.birthDateValue], () => {
  if (state.birthDateValue !== '') state.birthDateError = false
})

watch(() => [state.addressValue], () => {
  if (state.addressValue !== '') state.addressError = false
})

watch(() => [state.cityValue], () => {
  if (state.cityValue !== '') state.cityError = false
})

watch(() => [state.stateValue], () => {
  if (state.stateValue !== '') state.stateError = false
})

watch(() => [state.zipValue], () => {
  if (state.zipValue !== '') state.zipError = false
})

function firstNameValidation() {
  state.firstNameError = state.firstNameValue.length === 0;
}

function lastNameValidation() {
  state.lastNameError = state.lastNameValue.length === 0;
}

function emailValidation() {
  if (!state.emailValue || state.emailValue.length === 0) {
    state.emailError = true
    return
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validFormat = re.test(String(state.emailValue).toLowerCase());

  if (!validFormat) state.emailError = true
}

function phoneValidation() {
  if (!state.phoneValue || state.phoneValue.length < 14) state.phoneError = true
}

function birthDateValidation() {
  if (state.birthDateValue === '' || state.birthDateValue == null) state.birthDateError = true
}

function addressValidation() {
  if (state.addressValue === '') state.addressError = true
}

function cityValidation() {
  state.cityError = state.cityValue.length === 0;
}

function stateValidation() {
  state.stateError = state.stateValue === '';
}

function zipValidation() {
  state.zipError = state.zipValue.length < 5;
}

function checkForErrors() {
  firstNameValidation()
  lastNameValidation()
  emailValidation()
  phoneValidation()
  birthDateValidation()
  addressValidation()
  cityValidation()
  stateValidation()
  zipValidation()

  return [
    state.firstNameError,
    state.lastNameError,
    state.emailError,
    state.phoneError,
    state.birthDateError,
    state.addressError,
    state.cityError,
    state.stateError
  ].some(error => error === true);
}

async function sendValues() {
  state.loading = true
  state.buttonValue = 'LOADING...'
  if (checkForErrors()) {
    state.loading = false
    state.buttonValue = 'GET MY FREE FINAL EXPENSE QUOTE'
    return
  }

  const data = {
    lp_campaign_id: 5696,
    lp_supplier_id: 10899,
    lp_key: 'kdbjqpayx0',
    first_name: state.firstNameValue,
    last_name: state.lastNameValue,
    email: state.emailValue,
    phone: state.phoneValue.replace(/[()\-\s]+/g, ''),
    dob: format(new Date(state.birthDateValue), 'yyyy-MM-dd'),
    address: state.addressValue,
    city: state.cityValue,
    state: state.stateValue,
    zip: state.zipValue,
    user_agent: state.userAgent,
    ip: state.ipAddress,
    jornaya_id: state.jornaya_id
  }

  await axios.post('https://api.leadprosper.io/ingest', data)
    .then(() => {
      router.push({ name: 'thank-you'})
    })
    .catch(error => {
      console.log(error.response);
      router.push({ name: 'thank-you'})
    });
}
</script>

<template>
  <div class="form-section">
    <div class="overlay"></div>
    <div class="form-container">
      <div class="text-container">
        <h3>Final expense insurance is easy to qualify for, with no medical exam required. As long as you fall between the age limits of 50 to 85 years old, you can often get approved for a policy within days. Also sometimes called “funeral insurance” or “burial insurance,” final expense life insurance typically provides a guaranteed payout to help loved ones pay for a funeral service, burial or cremation, doctor or hospital bills, or other immediate expenses after a death.</h3>
      </div>
      <div class="form">
        <div
          v-if="state.loading"
          class="disabled-overlay"
        >
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="container">
          <div class="row mobile">
            <div class="box">
              <h3 class="first">Final expense insurance is easy to qualify for, with no medical exam required.</h3>
              <h3>Fill out the form below and find out instantly if you have a case.</h3>
            </div>
          </div>
          <div class="row desktop">
            <div class="box">
              <h3 class="first">Please fill out your contact information</h3>
            </div>
          </div>
          <div class="row">
            <div class="box">
              <label>First Name</label>
              <input
                v-model="state.firstNameValue"
                :class="{ 'input-error' : state.firstNameError}"
                placeholder="John"
                type="text"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.firstNameError">Please provide a first name</span>
              </div>
            </div>
            <div class="box">
              <label>Last Name</label>
              <input
                v-model="state.lastNameValue"
                :class="{ 'input-error' : state.lastNameError}"
                type="text"
                placeholder="Doe"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.lastNameError">Please provide a last name</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="box">
              <label>Email</label>
              <input
                v-model="state.emailValue"
                :class="{ 'input-error' : state.emailError }"
                type="text"
                placeholder="john.doe@gmail.com"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.emailError">Please provide a valid email</span>
              </div>
            </div>
            <div class="box">
              <label>Phone</label>
              <input
                v-model="state.phoneValue"
                :class="{ 'input-error' : state.phoneError }"
                v-maska="'(###) ###-####'"
                type="text"
                placeholder="(888) 412-1858"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.phoneError">Please provide a valid phone number</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="box">
              <label>Date of birth</label>
              <DatePicker
                v-model="state.birthDateValue"
                v-maska="'##/##/####'"
                :min-date="minDate"
                :max-date="maxDate"
                :popover="{ visibility: 'click' }"
                color="green"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    :value="inputValue"
                    :class="{ 'input-error' : state.birthDateError }"
                    v-on="inputEvents"
                    class="bg-white px-2 py-1 rounded date-picker-input"
                    placeholder="dd/mm/yyyy"
                  />
                </template>
              </DatePicker>
              <div class="error-container">
                <span v-if="state.birthDateError">Please provide a date of birth</span>
              </div>
            </div>
            <div class="box">
              <label>Add&#8204;ress</label>
              <input
                v-model="state.addressValue"
                :class="{ 'input-error' : state.addressError }"
                type="text"
                placeholder="123 Main Ave"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.addressError">Please provide a valid address</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="box">
              <label>City</label>
              <input
                v-model="state.cityValue"
                :class="{ 'input-error' : state.cityError }"
                type="text"
                placeholder="Sacramento"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.cityError">Please provide a city</span>
              </div>
            </div>
            <div class="box">
              <label>State</label>
              <select
                v-model="state.stateValue"
                :class="{ 'input-error' : state.stateError }"
              >
                <option disabled selected value="">
                  Select state
                </option>
                <option
                  v-for="(value, key) in stateList"
                  :value="key"
                >
                  {{ value }}
                </option>
              </select>
              <div class="error-container">
                <span v-if="state.stateError">Please provide a state</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="box">
              <label>Zip Code</label>
              <input
                v-model="state.zipValue"
                :class="{ 'input-error' : state.zipError }"
                v-maska="'#####'"
                type="text"
                placeholder="95014"
                autocomplete="off"
              >
              <div class="error-container">
                <span v-if="state.zipError">Please provide a valid zip code</span>
              </div>
            </div>
            <div class="box mobile">
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="box">
              <div class="submit-button-container">
                <button
                  @click="sendValues"
                  :disabled="state.loading"
                  :class="'submit-button-disabled'"
                  class="button-55"
                >
                  {{ state.buttonValue }}
                </button>
              </div>
            </div>
          </div>
          <TCPACompliance />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-section {
  width: 100%;
  background-image: url("../assets/images/finalexpensefund.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #00000052;
    position: absolute;
    z-index: 1;
  }
  .form-container {
    padding: 20px;
    max-width: 1200px;
    margin: 70px auto auto auto;
    display: flex;
    z-index: 20;
    .form {
      z-index: 50;
      max-width: 800px;
      padding: 8px 8px;
      background-color: #dbf7e9;
      border: 1px solid #007b56;
      border-radius: 8px;
      margin: 0;
      position: relative;
      overflow: hidden;
      .disabled-overlay {
        top: 0;
        left: 0;
        position: absolute;
        background-color:rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 100%;
        z-index: 20;
        cursor: wait;
        display: flex;
        justify-content: center;
        align-items: center;
        .lds-roller {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-roller div {
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          transform-origin: 40px 40px;
          &:after {
            content: " ";
            display: block;
            position: absolute;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #63d1a2;
            margin: -4px 0 0 -4px;
          }
          &:nth-child(1) {
            animation-delay: -0.036s;
            &:after {
              top: 63px;
              left: 63px;
            }
          }
          &:nth-child(2) {
            animation-delay: -0.072s;
            &:after {
              top: 68px;
              left: 56px;
            }
          }
          &:nth-child(3) {
            animation-delay: -0.108s;
            &:after {
              top: 71px;
              left: 48px;
            }
          }
          &:nth-child(4) {
            animation-delay: -0.144s;
            &:after {
              top: 72px;
              left: 40px;
            }
          }
          &:nth-child(5) {
            animation-delay: -0.18s;
            &:after {
              top: 71px;
              left: 32px;
            }
          }
          &:nth-child(6) {
            animation-delay: -0.216s;
            &:after {
              top: 68px;
              left: 24px;
            }
          }
          &:nth-child(7) {
            animation-delay: -0.252s;
            &:after {
              top: 63px;
              left: 17px;
            }
          }
          &:nth-child(8) {
            animation-delay: -0.288s;
            &:after {
              top: 56px;
              left: 12px;
            }
          }
        }

        @keyframes lds-roller {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      .container .row.mobile {
        display: none;
      }
      .line {
        margin: 20px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125)
      }
      .row {
        display: flex;
        justify-content: center;
        .box {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 8px 10px 0 10px;
          justify-content: space-between;
          label {
            line-height: 1.5;
            font-size: 21px;
            font-weight: 500;
            color: #005232;
            font-family: Arial, sans-serif;
          }
          h3 {
            margin: 0;
            color: #005232;
            font-family: Arial, sans-serif;
            font-size: 24px;
            text-align: center;
          }
          h3.first {
            margin-bottom: 30px;
          }
          h3.sub-title {
            font-weight: 400;
            padding: 20px;
            font-size: 20px;
          }
          input {
            border: 1px solid #007b56;
            background-color: #fff;
            padding: 8px 12px;
            border-radius: 5px;
            font-size: 19px;
            color: #005232;
            width: 100%;
            &.input-error {
              border-color: red;
            }
          }
          input:focus {
            outline: 0;
            box-shadow: 0 0 0 .5px #007b56;
          }
          select {
            border: 1px solid #007b56;
            background-color: #fff;
            padding: 8px 12px;
            border-radius: 5px;
            font-size: 19px;
            color: #005232;
            width: 100%;
            &.input-error {
              border-color: red;
            }
            &:focus {
              outline: 0;
              box-shadow: 0 0 0 .5px #007b56;
            }
          }
          .error-container {
            min-height: 18px;
            span {
              font-family: Arial, sans-serif;
              padding-left: 5px;
              color: red;
              font-size: 14px;
            }
          }
          p {
            text-align: justify;
            color: #6c757d;
            font-size: 11px;
          }
          .submit-button-container {
            margin: auto;
            .button-55 {
              align-self: center;
              background-color: #63d1a2;
              background-image: none;
              background-position: 0 90%;
              background-repeat: repeat no-repeat;
              background-size: 4px 3px;
              border-style: solid;
              border-width: 1px;
              box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
              box-sizing: border-box;
              color: #fff;
              cursor: pointer;
              display: inline-block;
              font-family: Arial, sans-serif;
              font-size: 1rem;
              line-height: 23px;
              outline: none;
              padding: .75rem;
              text-decoration: none;
              transition: all 235ms ease-in-out;
              border-radius: 5px;
              user-select: none;
              -webkit-user-select: none;
              touch-action: manipulation;
              border-color: #007b56;
              text-shadow: 1px 1px 1px #007b56;
              margin: 20px;
            }

            .button-55:hover {
              box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
              transform: translate3d(0, 2px, 0);
              background-color: #00AB6D;
            }

            .button-55:focus {
              box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
            }
          }
        }
        .box.no-bottom-margin {
          margin: 10px 10px 0 10px;
        }
        .form-header {
          text-align: center;
          h2 {
            color: #00283f;
            text-transform: uppercase;
            margin: 12px 0 0 0;
            font-family: sans-serif, 'Roboto';
            font-size: 30px;
          }
          .phone {
            color: black;
            text-decoration: none;
            font-size: 30px;
          }
          .phone:hover {
            color: red;
            text-decoration: underline;
          }
        }
      }
      .row.max-w-300 {
        width: 50%;
      }
      .row.form-header-background {
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
      }
    }
    .text-container {
      max-width: 400px;
      font-family: Arial, sans-serif;
      color: white;
      z-index: 50;
      h1 {
        text-transform: uppercase;
        text-shadow: 3px 3px 0 #000;
        font-size: 45px;
      }
      h3 {
        text-shadow: 0 0 10px #000;
        font-size: 25px;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 1200px) {
  .form-section {
    .form-container {
      flex-direction: column;
      .text-container {
        max-width: 800px;
        width: 100%;
        margin: auto;
        padding-bottom: 20px;
        padding-top: 0;
      }
      .form {
        margin: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .form-section {
    background: none;
    .overlay {
      display: none;
    }
    .form-container {
      .text-container {
        display: none;
      }
      .form {
        .container .row.mobile {
          display: block;
          .box {
            width: calc(100% - 20px);
          }
        }
        .container .row.desktop {
          display: none;
        }
        .container .row {
          display: block;
          .box  {
            margin: 5px 10px 0 10px;
            width: calc(100% - 20px);
            h3.first {
              font-size: 20px;
              margin-bottom: 15px;
            }
            h3 {
              font-size: 20px;
              margin-bottom: 15px;
            }
            input {
              width: 100%;
              font-size: 18px;
            }
            label {
              font-size: 16px;
            }
            .error-container {
              span {
                font-size: 13px;
              }
            }
            .submit-button-container .button-55 {
              margin: 0 0 15px 0;
            }
          }
        }
      }
    }
  }
}
</style>