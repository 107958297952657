<script>
export default {
  name: "TortInformation.vue",
  props: {
    tortDescription: String,
    tortImage: String
  },
  setup() {
    const scrollToForm = () => {
      const el = document.querySelector('.form');
      window.scrollTo({
        top: el.offsetTop - 80,
        behavior: "smooth"
      })
    }
    return {
      scrollToForm,
    }
  }
}
</script>

<template>
  <div class="tort-description-container">
    <div class="tort-description-wrapper">
      <div class="tort-image">
        <div class="img"></div>
      </div>
      <div class="tort-description-text">
        <p>The average funeral can cost upwards of $10,000, not including other end-of-life expenses such as medical bills. Without life insurance, these costs fall on the shoulders of loved ones and cause financial strain.</p>
        <p>Final expense life insurance, also called burial insurance, is a limited type of life insurance coverage used for costs associated with death and funeral arrangements. Due to its high cost for lower coverage amounts, final expense insurance is best suited for people who are ineligible for traditional coverage.</p>
        <button class="button-55" @click="scrollToForm" role="button">GET MY FREE FINAL EXPENSE QUOTE</button>
      </div>
    </div>
  </div>

</template>


<style scoped lang="scss">
  .tort-description-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dbf7e9;
    height: auto;
    width: 100%;
    .tort-description-wrapper {
      height: 100%;
      display: flex;
      max-width: 1200px;
      padding: 50px 0;
      .tort-description-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        width: 50%;
        p {
          font-family: Arial, sans-serif;
          line-height: 1.52rem;
          color: #005232;
          font-size: 20px;
          margin-bottom: 30px;
        }
        .button-55 {
          align-self: center;
          background-color: #63d1a2;
          background-image: none;
          background-position: 0 90%;
          background-repeat: repeat no-repeat;
          background-size: 4px 3px;
          border-radius: 5px;
          border-style: solid;
          border-width: 1px;
          box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: inline-block;
          font-family: Arial, sans-serif;
          font-size: 1rem;
          line-height: 23px;
          outline: none;
          padding: .75rem;
          text-decoration: none;
          transition: all 235ms ease-in-out;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;
          border-color: #007b56;
          text-shadow: 1px 1px 1px #007b56;
        }

        .button-55:hover {
          box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
          transform: translate3d(0, 2px, 0);
          background-color: #00AB6D;
        }

        .button-55:focus {
          box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
        }
      }
      .tort-image {
        display: flex;
        padding: 20px;
        width: 50%;
        .img {
          height: 400px; /* You must set a specified height */
          width: 100%; /* You must set a specified width */
          background-position: center; /* Center the image */
          background-repeat: no-repeat; /* Do not repeat the image */
          background-size: cover; /* Resize the background image to cover the entire container */
          border-radius: 10px;
          background-image: url('../assets/images/middle_area_couple.jpeg');
          box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        }
      }
    }

  }

@media(max-width: 1200px) {
  .tort-description-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .tort-description-wrapper {
      display: flex;
      flex-direction: column;
      .tort-image {
        padding-top: 20px;
        width: 100%;
        img {
          min-width: auto;
          margin: auto;
        }
      }
      .tort-description-text {
        padding: 15px 20px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .form {
    margin: 20px;
    .container .row {
      display: block;
      .box.question-w {
        width: 100%;
        text-align: center;
      }
      .box.answer-w {
        width: 100%;
      }
      .box .submit-button-container {
        max-width: 100%;
      }
      .box.mobile {
        display: none;
      }
    }
  }
}
</style>