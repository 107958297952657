import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Final Expense Fund' },
    component: Home
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    meta: { title: 'Final Expense Fund - Thank you' },
    component: () => import(/* webpackChunkName: "thank-you" */ '../views/ThankYou')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    meta: { title: 'Final Expense Fund - Terms of use' },
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUse')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: { title: 'Final Expense Fund - Privacy policy' },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy')
  },
  {
    path: '/partners',
    name: 'partners',
    meta: { title: 'Final Expense Fund - Partners' },
    component: () => import(/* webpackChunkName: "partners" */ '../views/PartnersList')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    meta: { title: 'Page not found' },
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 100,
      }
    }
  }
})

export default router
