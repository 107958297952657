<script setup>
const currentYear = new Date().getFullYear()
</script>

<template>
  <footer>
    <div class="footer-container">
      <div class="first-container">
        <div class="wrapper">
          <router-link to="/">
            <img src="../assets/images/small-logo.png" alt="Logo">
          </router-link>
        </div>
        <div class="footer-nav">
          <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
          <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
        </div>
      </div>
      <div class="second-container">
        <div class="text">
          Copyright © {{ currentYear }} Final Expense Found | All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  border-top: 1px solid #007b56;
  background-color: #63d1a2;
  z-index: 2;
  .footer-container {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    .first-container {
      border-bottom: 2px solid #a5e5c9;
      display: flex;
      justify-content: space-between;
      .wrapper {
        max-width: 246px;
        padding: 10px;
        margin: 20px;
        border-radius: 5px;
        img {
          max-width: 100%;
        }
      }
      .footer-nav {
        display: flex;
        align-items: center;
        .nav-button {
          margin: 0 20px;
          color: white;
          text-decoration: none;
          font-family: Arial, sans-serif;
          font-size: 18px;
          font-weight: bold;
          transition: .1s ease-in-out;
          &:hover {
            color: #007b56;
          }
        }
      }
    }
    .second-container {
      .text {
        color: #fff;
        font-family: Arial, sans-serif;
        font-weight: 400;
        margin: 20px auto 0 auto;
        padding-bottom: 20px;
        font-size: 0.875rem;
        line-height: 1.5rem;
        max-width: 90%;
      }
    }
  }
}

@media (max-width: 620px) {
  footer .container .first-container {
    display: block;
  .wrapper {
    margin: 20px auto;
  }
  .footer-nav {
    padding: 0 20px 20px 20px;
  }
}
}

@media (max-width: 400px) {
  footer .footer-container .first-container {
    display: inline-block;
    padding-top: 15px;
  .wrapper {
    margin: auto;
  }
  .footer-nav .nav-button {
    padding: 10px;
  }
}
}
</style>