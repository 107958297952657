<template>
  <header>
    <div class="header-container">
      <router-link to="/">
        <div class="logo-image"></div>
      </router-link>
    </div>
  </header>
</template>

<script setup>

</script>

<style lang="scss" scoped>
  header {
    height: 70px;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: #63d1a2;
    border-bottom: 1px solid #007b56;
    box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.6);
    -webkit-box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.6);
    -moz-box-shadow: 0 -2px 24px 0 rgba(0,0,0,0.6);
    .header-container {
      max-width: 1200px;
      height: 100%;
      margin: auto;
      padding: 0 20px;
      .logo-image {
        width: 100%;
        height: 70px;
        background-image: url('../assets/images/white-logo-no-background.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;
      }
    }
  }
  @media (max-width: 600px) {
    header .header-container .logo-image {
      background-position: center;
    }
  }
</style>