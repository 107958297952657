export const getJornayaLeadIdToken = () => {
  return new Promise(res => {
    const i = setInterval(() => {
      const input = document.getElementById("leadid_token")
      let leadid_token = ''
      if (input) {
        leadid_token = input.value
      }
      if (leadid_token) {
        clearInterval(i)
        res(leadid_token)
      }
    }, 50)
  })
}